import { countries, languages } from "../../global/countriesAndSources"

export default function SearchComponent({searchTerm, setSearchTerm, handleQuery, dropdownList, otherButton}) {
    return (
        <form onSubmit={handleQuery} className="flex flex-col lg:flex-row items-center mb-4">
            <div className="w-full lg:flex-grow lg:mr-2">
                <input
                type="text"
                placeholder="Rechercher..."
                className="w-full px-3 py-2 mb-2 lg:mb-0 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>
            {
                dropdownList &&
                    dropdownList.select === 'Pays' && (
                        <select value={dropdownList.value} onChange={dropdownList.handle} className="w-full lg:w-auto px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mb-2 lg:mb-0 lg:mr-2" name="country">
                            <option value={"ac"} key={-1}>Tous les pays</option>
                            {
                            countries.map((c, i) => <option value={c.code} key={i}>{c.name}</option>)
                            }
                        </select>
            )}
            {
                dropdownList &&
                    dropdownList.select === 'CountryLanguages' && (
                        <select value={dropdownList.value} onChange={dropdownList.handle} className="w-full lg:w-auto px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mb-2 lg:mb-0 lg:mr-2" name="country">
                            {
                            dropdownList.listLang.map((l, i) => <option value={l.language_code} key={i}>{l.language_name}</option>)
                            }
                        </select>
                    )
            }
            {
                dropdownList &&
                    dropdownList.select === 'AllLanguages' && (
                        <select value={dropdownList.value} onChange={dropdownList.handle} className="w-full lg:w-auto px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 mb-2 lg:mb-0 lg:mr-2" name="country">
                            {
                                languages.map((l, i) => <option value={l.language_code} key={i}>{l.language_name}</option>)
                            }
                        </select>
                    )
            }
            <button
                type="submit"
                className="w-full lg:w-auto mb-2 lg:mb-0 bg-green-500 hover:bg-green-700 text-white font-bold lg:mr-2 py-2 lg:px-4 rounded-lg whitespace-nowrap"
            >
                Recherche
            </button>
            {
                otherButton && 
                    <button
                        type="button"
                        className="w-full lg:w-auto bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-lg whitespace-nowrap"
                        onClick={otherButton.handle}
                    >
                        {otherButton.title}
                    </button>
            }
        </form>
    )
}