import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getArticleById } from "../../HTTP/articles";
import { updateVisibleArticle } from "../../HTTP/admin";

export default function CheckArticle() {
    const user = JSON.parse(localStorage.getItem("user"))
    if (!user.admin) {
        window.location.href = "/"
    }
    const { id } = useParams()
    const [formData, setFormData] = useState({
        id: id,
        user_id: user.id,
        title: '',
        description: '',
        visible: 0
    });

    useEffect(() => {
        const funArticles = async () => {
            const resArticle = await getArticleById(id)
            setFormData(resArticle)
        }
        funArticles()
      }, [id])
      
      const handlePublishArticle = async () => {
        const res = await updateVisibleArticle(id, 3, "")
        window.location.href = "/admin/articles"
      }

      const handleRefuseArticle = async () => {
        const comment = prompt("Mettre la raison du refus :")
        if (comment) {
            const res = await updateVisibleArticle(id, 0, comment)
            window.location.href = "/admin/articles"
        }
      }

      if (formData.visible === 0 && user.admin === 0 && user.id !== formData.user_id) {
        window.location.href = "/articles"
      }

    return (
        <>
            <div className="px-4 sm:px-6 md:px-8">
    <div className="max-w-2xl mx-auto mt-12 p-6 bg-white shadow-md max-h-[60vh] overflow-y-auto" dangerouslySetInnerHTML={{ __html: formData.description }} />

    <div className="max-w-2xl mx-auto"> {/* Conteneur pour aligner l'input et les boutons */}

        <div className="flex flex-col sm:flex-row sm:justify-end gap-3 mt-4">
            <button className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600" onClick={handlePublishArticle}>
                Accepter
            </button>
            <button className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600" onClick={handleRefuseArticle}>
                Refuser
            </button>
        </div>
    </div>
</div>
        </>
    )
}