import { getNestedValue, tailwindClassForColoredButton } from "../../Helper/utils";

export default function ElementMobile({ column, element, buttons }) {
    return (
        <div className="bg-white p-4 rounded-lg shadow-md mb-2">
            <div className="space-y-3">
                {column.map((col, index) => (
                    col.display === "Actions" ? (
                        <div key={`${col.props}-${index}`} className="py-2">
                            <div className="flex flex-col space-y-2">
                                {buttons.map((b, buttonIndex) => (
                                    <button
                                        key={`${b.display}-${buttonIndex}`}
                                        className={tailwindClassForColoredButton(b.color)}
                                        onClick={() => b.action(getNestedValue(element, b))}
                                    >
                                        {b.display}
                                    </button>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <p key={`${col.props}-${index}`} className="mb-2">
                            <span className="font-medium">{col.display} : </span>
                            {getNestedValue(element, col)}
                        </p>
                    )
                ))}
            </div>
        </div>
    );
}