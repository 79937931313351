
import { useEffect, useState } from "react";
import { countries as allCountries } from "../../global/countriesAndSources"
import {ProfileHeader } from "./profileHeader"
import externalScripts  from "../../Helper/useExternalScripts";
import GoogleCSE from "./GoogleCSE";
import thematics from "../../HTTP/thematics";
import translation from "../../HTTP/translation";
import Map from "../Map/Map";
import { CountryBar } from "./countryBar";
import "./GoogleCSE.css"
import { rgb } from "@amcharts/amcharts4/.internal/core/utils/Colors";

export default function TheCountrySpeaksOf () {
    const [displayMap, setDisplayMap] = useState(false)

    if (sessionStorage.getItem('search_country_country') !== null) {
        sessionStorage.removeItem('search_country_country')
    }

    const [themes, setThemes] = useState([])
    const [searchTheme, setSearchTheme] = useState("")
 
    let country;
    if(JSON.parse(sessionStorage.getItem('search_themes_country')) !== null) {
        country = JSON.parse(sessionStorage.getItem('search_themes_country'))
    } else {
        const queryCountry = new URLSearchParams(window.location.search).get('country')
        country = queryCountry ? allCountries.find((c) => c.code === queryCountry) : null
        country = country
                || JSON.parse(sessionStorage.getItem("search_country"))
                || allCountries.find((c) => c.code === JSON.parse(localStorage.getItem('user'))?.country)
                || { name: "France", code: "fr", cse: "004899679196972058950:gvpguoasgrm" };
        sessionStorage.setItem('search_themes_country',JSON.stringify(country))
    }
    
    const [selectedCountry, setSelectedCountry] = useState(country)
    const selectNewCountry = (country) => {
        setSelectedCountry(country)
        sessionStorage.setItem("search_themes_country", JSON.stringify(country))
    }

    const handleDisplayMap = () => {
		if(displayMap) {
			setDisplayMap(false)
			console.log(displayMap)
		} else {
			setDisplayMap(true)
			console.log(displayMap)
		}
	}

    useEffect (() => {
        const setThematics = async () => {
            const fromDb = await thematics.fetchThematicsFromCountry(JSON.parse(sessionStorage.getItem('search_themes_country')).code)
            setThemes(fromDb)
        }
        setThematics()
        console.log("mise en place terminée")
    }, [selectedCountry])

    const url = new URL(window.location.href)
    useEffect(() => {
        if (url.searchParams.get('theme') !== null) {
            setSelectedTheme(url.searchParams.get('theme'))
        }
    }, [])

    const [selectedTheme, setSelectedTheme] = useState("")
    
    
    const handleTheme = async (item) => {
        setSelectedTheme(item)
        url.searchParams.set('theme', item)
        window.history.pushState({}, '', url);
        const lang = await translation.getLangFromCountry(JSON.parse(sessionStorage.getItem("search_country")).code)
        let query;
        if (lang !== 'fr') {
            query = await translation.getTranslation('fr', lang, item)
        } else {
            query = item
        }
        setSearchTheme(query)
        const input = document.querySelector('input#gsc-i-id1')
        sessionStorage.setItem("search_query",query)
        window.launchSearch = false
        console.log("la recherche est lancée sur le terme " + query)
        if (query) {
            for (let i = 0; i < 5; i++){setTimeout(() => input.value = query, 500)}
        }
        const button = document.querySelector("button.gsc-search-button-v2")
        if (button){
            for (let i = 0; i < 5; i++){setTimeout(() => button.click(), 500)}
        }
        window.launchSearchDone = true
        console.log(item)
    }
    
            
    return (
        <>
        <ProfileHeader 
                thematics = {themes}
                selectedCountry={selectedCountry}
                selectedTheme = {selectedTheme}
                setSelectedCountry = {(country) => selectNewCountry(country)}
                searchThematics = {(theme) => handleTheme(theme)}
                displayMap = {() => handleDisplayMap()}
            />
        <div className="flex">
            <div className="flex-1">
                <h1 className="ml-3">De quels pays parle-t-on ?</h1>
                <Map 
                    selectedCountry={selectedCountry} 
                    setSelectedCountry = {(country) => selectNewCountry(country)}
                    displayMap = {displayMap}
                    setDisplayMap = {(hide) => setDisplayMap(hide)}
                />
                <GoogleCSE 
                    selectedCountry = {country}
                />
            </div>
            <div id="pays_large" className="w-60">
            <CountryBar 
                selectedCountry = {selectedCountry}
                setSelectedCountry = {(country) => selectNewCountry(country)}
                displayMap = {() => handleDisplayMap()}
                searchThematics = {(theme) => handleTheme(theme)}
                thematics = {themes}
            />
            </div>
        </div>
        
        </>
    )
}