import authHeader from "./auth.header"
import constants from './constants.js'

const HOST_URL = constants.API_SCHEME + "://" + constants.API_HOST_NAME + ":" + constants.API_PORT;

export async function getAllUsers(firstElement, lastElement, country) {
    const token = localStorage.getItem("token")

    const response = await fetch(`${HOST_URL}/users/all/${token}/${firstElement}/${lastElement}/${country}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    const jsonResponse = await response.json()
    return jsonResponse
}

export async function getUsersByQuery(firstElement, lastElement, query, country) {
    const token = localStorage.getItem("token")

    const response = await fetch(`${HOST_URL}/users/all/${token}/${firstElement}/${lastElement}/${query}/${country}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    const jsonResponse = await response.json()
    return jsonResponse
}

export async function getNumberOfUsers(country) {
    const userAdmin = JSON.parse(localStorage.getItem('user')).admin
    if (!userAdmin) return

    const response = await fetch(`${HOST_URL}/users/count/${country}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }
    console.log('API call ok')
    return response.json()
}

export async function getNumberOfQueryUsers(query, country) {
    const userAdmin = JSON.parse(localStorage.getItem('user')).admin
    if (!userAdmin) return

    const response = await fetch(`${HOST_URL}/users/count/${query}/${country}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }
    console.log('API call ok')
    return response.json()
}

export async function getUserById(userId) {
    const userAdmin = JSON.parse(localStorage.getItem('user')).admin
    if (!userAdmin) return

    const response = await fetch(`${HOST_URL}/users/${userId}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }
    console.log('API call ok')
    return response.json()
}

export async function addNewUser(userInfo) {
    const userAdmin = JSON.parse(localStorage.getItem('user')).admin
    if (!userAdmin) return
    let response = await fetch(HOST_URL + "/users", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
        body: JSON.stringify(userInfo)
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return await response.json()
    }

    return await response.json()
}

export async function updateUser(id, request) {
    console.log('send requet to API')
    try { 
        if (!id) return 'No user id get lost'
        const response = await fetch(`${HOST_URL}/users/${id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
            body: JSON.stringify(request) // Ajout du contenu de 'request' au corps de la requête
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
        
       const responseData = await response.json()
       console.log(responseData)

       return responseData;
    }
    catch (err) {
        console.log("err at updateUser", err)
    }
}

export async function removeUser(userId) {
    try {
        if (!JSON.parse(localStorage.getItem("user")).admin) {
            return
        }
        const response = await fetch(`${HOST_URL}/users/${userId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
        
        console.log(await response.json())
        return true
    } catch (err) {
        console.log("err at removeUser", err)
    }
}

export async function fetchReviewCount(query) {
    const userAdmin = JSON.parse(localStorage.getItem('user')).admin
    if (!userAdmin) return

    const response = await fetch(`${HOST_URL}/reviews/count${userAdmin.super_admin === 0 ? '/'+userAdmin.country : ''}${query ? "/"+query : ""}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }
    console.log('API call ok')
    return response.json()
}

export async function fetchReviews(firstElement, lastElement, query) {
    const user = JSON.parse(localStorage.getItem("user"))
    const response = await fetch(`${HOST_URL}/reviews/${user.super_admin === 0 ? user.country : 'ac'}/${firstElement}/${lastElement}${query ? "/"+query : ""}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    const jsonResponse = await response.json()
    return jsonResponse
    // const favorites = jsonResponse.favorites
    // return favorites
}

export async function fetchQueryReviews(firstElement, lastElement, query) {
    const response = await fetch(`${HOST_URL}/reviews/${firstElement}/${lastElement}/${query}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    const jsonResponse = await response.json()
    return jsonResponse
    // const favorites = jsonResponse.favorites
    // return favorites
}

export async function getNumberOfQueryReview(query) {
    const userAdmin = JSON.parse(localStorage.getItem('user')).admin
    if (!userAdmin) return

    const response = await fetch(`${HOST_URL}/reviews/count/${query}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }
    console.log('API call ok')
    return response.json()
}

export async function fetchCountFavorites(query) {
    const userAdmin = JSON.parse(localStorage.getItem('user')).admin
    if (!userAdmin) return
    let response
    if (query !== '') {
        response = await fetch(`${HOST_URL}/favorites/count/${query}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    } else {
        response = await fetch(`${HOST_URL}/favorites/count`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    }

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    const jsonResponse = await response.json()
    return jsonResponse
}

export async function fetchAllFavorites(firstElement, lastElement, query) {
    const userAdmin = JSON.parse(localStorage.getItem('user')).admin
    if (!userAdmin) return
    let response
    if (query !== '') {
        response = await fetch(`${HOST_URL}/favorites/all/${firstElement}/${lastElement}/${query}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    } else {
        response = await fetch(`${HOST_URL}/favorites/all/${firstElement}/${lastElement}`, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                ...authHeader()
            },
        })
    }

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    const jsonResponse = await response.json()
    return jsonResponse
}

export async function removeFavorite(favId) {
    try {
        if (!JSON.parse(localStorage.getItem("user")).admin) {
            return
        }
        const token = localStorage.getItem("token")
        const response = await fetch(`${HOST_URL}/favorites/admin/remove`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                ...authHeader()
            },
            body: JSON.stringify({token: token, id: favId, user_id: JSON.parse(localStorage.getItem("user")).id})
        })
    
        if (!response.ok) {
            console.log("request failed\n. response code, ", response.code)
            console.log("response payload, ", await response.json())
            return false
        }
        
        console.log(await response.json())
        return true
    } catch (err) {
        console.log("err at removeUser", err)
    }
}

export async function getAdminNotifications() {
    const user = JSON.parse(localStorage.getItem('user'))
    if (!user.id) return
    const response = await fetch(`${HOST_URL}/notifications/admin/${user.id}${user.super_admin === 0 ? '/'+user.country : ''}`, {
        method: 'GET', 
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        },
    })
    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

export async function getAdminCountNotifications() {
    const userAdmin = JSON.parse(localStorage.getItem("user"))
    if (!userAdmin.admin) return
    const response = await fetch(`${HOST_URL}/notifications/count/admin${userAdmin.super_admin === 0 ? '/'+userAdmin.country : ''}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            ...authHeader()
        }
    })
    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return []
    }

    return await response.json()
}

export async function updateVisibleArticle(articleId, visibility, comment) {
    const userAdmin = JSON.parse(localStorage.getItem("user")).admin
    if (!userAdmin) return
    const token = localStorage.getItem("token");
    const response = await fetch(`${HOST_URL}/article/update/visibility`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify({
            'id': articleId,
            'visible' : visibility,
            'token': token,
            'comment': comment
        })
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return false
    }

    return true
}

export async function sendNotification(data) {
    const userAdmin = JSON.parse(localStorage.getItem('user')).admin
    if (!userAdmin) return
    let response = await fetch(HOST_URL + "/notifications/send", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            ...authHeader()
        },
        body: JSON.stringify(data)
    })

    if (!response.ok) {
        console.log("request failed\n. response code, ", response.code)
        console.log("response payload, ", await response.json())
        return await response.json()
    }

    return await response.json()

}