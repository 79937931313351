import { useEffect, useState } from "react";
import { deleteNotification, getNotifications, updateSeenNotification } from "../../HTTP/user";
import { formatDate, formatDateExtended } from "../../Helper/utils";

export default function Notifications() {

    const [notifications, setNotifications] = useState([])
    useEffect(() => {
        const funNotifications = async () => {
            const resNotifs = await getNotifications()
            setNotifications(resNotifs)
        }
        funNotifications()
    }, [])

    useEffect(() => {
      const funSeeNotifications = async () => {
        notifications.map(async (item) => {
          if (item.seen === 0) {
            const res = await updateSeenNotification(item.id)
            if (res) {
              console.log("notification vue")
            } else {
              console.log("erreur pour mettre en vue les notifications")
            }
          }
        })
      }
      funSeeNotifications()
    }, [notifications])

    

    const handleSeeNotification = async (id) => {
        const res = await updateSeenNotification(id)
        if (res) {
            alert("notification vue")
            window.location.href = "/notifications"
        } else {
            alert("erreur")
        }
    }

    const handleDelete = async (id) => {
        const res = await deleteNotification(id)
        if (res) {
            alert("notification supprimée")
            window.location.href = "/notifications"
        } else {
            alert("erreur")
        }
    }

    return (
        <>
        <div className="w-full px-4 sm:px-6 lg:px-8">
      <div className="flex items-center justify-between mt-10 mb-4">
        <h1 className="text-2xl font-bold text-center flex-grow">
          Mes notifications
        </h1>
      </div>

      <div className="w-full overflow-x-auto shadow-md rounded-lg">
        <table className="w-full table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Message</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              {/* <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th> */}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {notifications.map((item, index) => (
              <tr key={item.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-4 py-4 break-words">
                  <div className={item.seen === 0 ? " font-bold" : "font-normal" + "max-w-xs lg:max-w-sm xl:max-w-md"}>{item.message}</div>
                </td>
                <td className="px-4 py-4 break-words">
                  <div className="max-w-xs lg:max-w-sm xl:max-w-md">{formatDateExtended(item.date)}</div>
                </td>
                <td className="px-4 py-4 whitespace-nowrap">
                  {/* {
                    item.seen === 0 && <button 
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                    onClick={() => {handleSeeNotification(item.id)
                    }}
                  >
                    Marqué comme vu
                  </button>
                  } */}
                  {/* <button 
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => {
                        if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
                          handleDelete(item.id);
                        }
                      }}
                  >
                    Supprimer
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
        </>
    )
}