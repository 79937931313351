import etoille from '../../media/etoille.png'
import React, { useEffect, useState } from 'react';
import { getArticles, getArticlesByAuthorId, removeOwnArticle } from '../../HTTP/articles';
import imgArticle from "../../media/ICONS THEMATIQUE/Fichier 5_1.png"
import { formatDate, htmlDecode, removeHtmlTags } from '../../Helper/utils';


const ArticleCard = ({ imageUrl, source, title, text }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-xl h-full">
    <div className="flex flex-col h-full">
      <img
        className="w-full h-48 object-cover flex-shrink-0"
        src={imageUrl}
        alt={title}
      />
      <div className="p-4 flex-grow flex flex-col">
        <div className="flex items-center mb-2">
          <img
            className="w-8 h-8 rounded-full mr-2"
            src={imageUrl}
            alt="article"
          />
          <p className="text-sm">
            {source} Source: <span className="text-blue-400">Politique</span>
          </p>
        </div>
        <h3 className="text-lg font-semibold mb-2">{title}</h3>
        <p className="text-gray-600 flex-grow">
          {text.length > 150 ? text.slice(0, 150) + "..." : text}
        </p>
      </div>
    </div>
  </div>
);

export default function Articles() {
  const url = new URL(window.location.href)
      if (url.searchParams.get('page') === null) {
          url.searchParams.set('page', 1);
          window.history.pushState({}, '', url);
      }

  const [articles, setArticles] = useState({count:0, rows:[]});
  const [searchTerm, setSearchTerm] = useState(url.searchParams.get('query') === null ? '' : url.searchParams.get('query'));
  const [currentPage, setCurrentPage] = useState(parseInt(url.searchParams.get('page')));
  const [itemsPerPage] = useState(8);
  const user = JSON.parse(localStorage.getItem("user"))


  useEffect(() => {
    const funArticles = async () => {
        const resArticles = await getArticles((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage, searchTerm, 1, "al")
        setArticles(resArticles)
    }
    funArticles()
  }, [])

  const handleSearch = (e) => {
    e.preventDefault();
    if (url.searchParams.get('query') === null) {
        url.searchParams.set('query', searchTerm);
        window.history.pushState({}, '', url);
    }
    window.location.href = "/articles?page=1&query=" + searchTerm
  };

  const handleViewArticle = (id) => {
    window.location.href = "/articles/view/" + id
  }

  const handleMyArticles = () => {
    window.location.href = "/myarticles"
  }

  // Changer de page
  const paginate = (pageNumber) => window.location.href = "/articles?page=" + pageNumber + (url.searchParams.get("query") === null ? '' : "&query=" + url.searchParams.get("query"));

  return (
  <section className="articles">
    <div className="flex items-center mb-4">
      <div className="mr-2">
        <img className="w-6 h-6" src={etoille} alt="étoile" />
      </div>
      <h1 className="text-2xl font-bold">Derniers articles publiés</h1>
    </div>

    <form onSubmit={handleSearch} className="flex w-full items-center mb-4">
        <div className="flex-grow mr-2">
          <input
            type="text"
            placeholder="Rechercher..."
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className="bg-green-500 hover:bg-green-700 text-white font-bold mr-2 py-2 px-4 rounded-md whitespace-nowrap"
        >
          Recherche
        </button>
        <button
          type="button"
          className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-md whitespace-nowrap"
          onClick={handleMyArticles}
        >
          Mes articles
        </button>
      </form>
    
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {
          articles.rows.map((item) => (
            <a onClick={() => handleViewArticle(item.id)}>
              <ArticleCard
                imageUrl={imgArticle}
                source="Zemus"
                title={item.title}
                text={decodeURI(removeHtmlTags(item.description))}
              />
            </a>
          ))
        }
    </div>
    <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={articles.count}
        paginate={paginate}
        currentPage={currentPage}
      />
  </section>
  )
}

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="mt-4">
      <ul className="flex justify-center">
        {pageNumbers.map(number => (
          <li key={number} className="mx-1">
            <button
              onClick={() => paginate(number)}
              className={`px-3 py-1 rounded ${currentPage === number ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
            >
              {number}
            </button>
          </li>
        ))}
      </ul>
    </nav>
  );
};
