import { getNestedValue, tailwindClassForColoredButton } from "../../Helper/utils"

export default function LineForTable({columns, element, index, buttons}) {
    return (
        <tr className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
            {columns.map((column, colIndex) => {
                if (column.display === "Actions") {
                    return (
                        <td key={`${index}-${colIndex}`} className="px-4 py-4 break-words">
                            <div className="flex flex-col space-y-2">
                                {buttons.map((b, buttonIndex) => (
                                    <button
                                        key={`${index}-${colIndex}-${buttonIndex}`}
                                        className={tailwindClassForColoredButton(b.color)}
                                        onClick={() => b.action(getNestedValue(element, b))}
                                    >
                                        {b.display}
                                    </button>
                                ))}
                            </div>
                        </td>
                    );
                }
                
                return (
                    <td key={`${index}-${colIndex}`} className="px-4 py-4 break-words">
                        <div className="max-w-xs lg:max-w-sm xl:max-w-md">
                            {getNestedValue(element, column)}
                        </div>
                    </td>
                );
            })}
        </tr>
    );
}