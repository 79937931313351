import ElementMobile from "./ElementMobile";

export default function ListElementsMobile({columns, data, buttons}) {
    return (
        <div className="h-[calc(100vh-18rem)] overflow-y-auto">
            {data.map((item, index) => (
                <ElementMobile 
                    key={item.article?.id || `item-${index}`}
                    column={columns} 
                    element={item} 
                    buttons={buttons}
                />
            ))}
        </div>
    );
}