import { ViewKanban } from "@mui/icons-material";
import { countries } from "../global/countriesAndSources";


/**
 * @arg {string} text
 * Decodes the urls which have characters as HTML entities.
 * @returns {string}
**/
export function htmlDecode (text) {
    var textArea = document.createElement('textarea')
    textArea.innerHTML = text
    return textArea.value
}


export function removeHtmlTags(str) {
  return str.replace(/<[^>]*>/g, ' ');
}

export function encodeHTML(str) {
  return str.replace(/[&<>'"]/g, 
      tag => ({
          '&': '&amp;',
          '<': '&lt;',
          '>': '&gt;',
          "'": '&#39;',
          '"': '&quot;'
      }[tag] || tag)
  );
}

export function decodeHTML(str) {
  const element = document.createElement('div');
  if(str && typeof str === 'string') {
      // Remplace les entités HTML par leurs caractères correspondants
      element.innerHTML = str;
      return element.textContent;
  }
  return str;
}

// Fonction pour tronquer une chaîne de caractères
export const truncateString = (str, num) => {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + '...';
  };

// Fonction pour vérifier qu'une url est dans un tableau
export function checkLink(array, url) {
      function decodeHtmlEntities(encodedString) {
        var replacements = {
            "&#x2F;": "/",
            // Ajoutez d'autres remplacements d'entités HTML ici si nécessaire
        };

        return encodedString.replace(/&#x2F;|&#x2F;/gi, function(matched){
            return replacements[matched];
        });
    }
    // Décoder l'URL pour la comparaison
    const decodedUrl = decodeURIComponent(url); 
    return array.some(element => decodeHtmlEntities(element.link) === decodedUrl);
}

// Fonction pour décoder des entités HTML
export function decodeHtmlEntities(encodedString) {
  const replacements = {
    "&#x2F;": "/",
    "&#x27;": "'",
    "&amp;#x2F;": "/",
    // Ajoutez d'autres remplacements d'entités HTML ici si nécessaire
  };
  
  return encodedString.replace(/&#x2F;|&#x27;|&amp;#x2F;/gi, function(matched) {
    return replacements[matched];
  });
  
}

export function escapeQuotes(str) {
  return str.replace(/['"]/g, '\\$&');
}

// Fonction pour retirer une URL dans un tableau
export function removeLink(array, url) {
  // Décoder l'URL pour la comparaison
  const decodedUrl = decodeURIComponent(url); 
  return array.filter(element => decodeHtmlEntities(element.link) !== decodedUrl);
}

export function formatDate(dateString) {
  const date = new Date(dateString);

  // Récupération des composants de la date
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois en JavaScript commencent à 0
  const year = date.getFullYear();

  // Formatage de la date au format DD-MM-YYYY
  return `${day}-${month}-${year}`;
}

export function formatDateExtended(dateString) {
  const date = new Date(dateString);

  // Récupération des composants de la date
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Les mois en JavaScript commencent à 0
  const year = date.getFullYear();
  const hours = String(date.getHours())
  const minutes = String(date.getMinutes()).padStart(2, '0')

  // Formatage de la date au format DD-MM-YYYY
  return `${day}-${month}-${year} ${hours}:${minutes}`;
}

export function getDomainName(url) {
  try {
    const urlObj = new URL(url);
    return urlObj.hostname; // Retourne le nom de domaine
  } catch (error) {
    console.error("Erreur lors de l'extraction du nom de domaine:", error);
    return null; // ou une chaîne vide '' selon ce qui est préférable pour votre cas d'utilisation
  }
}

export const getNameCountryFromCode = (code) => {
  return countries.find((c) => c.code === code).name
}

export const getNestedValue = (obj, column) => {
  const { props, format } = column;
  
  // Vérifie si props est un tableau, sinon le convertit en tableau
  const propsArray = Array.isArray(props) ? props : [props];
  
  // Récupère toutes les valeurs demandées
  const values = propsArray.map(prop => {
    return prop.split('.').reduce((current, key) => {
      return current ? current[key] : undefined;
    }, obj);
  });

  // Application du formatage
  if (format) {
    if (typeof format === 'function') {
      return format(values, obj);
    }
    if (typeof format === 'string' && formatters[format]) {
      return formatters[format](values, obj);
    }
  }

  // Si pas de formatage spécifique, on joint les valeurs avec un espace
  return values.filter(Boolean).join(' ') || '-';
};

export const displayShortcutedArticle = (article) => {
  return removeHtmlTags(article).length > 150 ? removeHtmlTags(article).slice(0,150) + "..." : removeHtmlTags(article)
}

export const formatters = {
  country: (values) => {
    const value = values[0];
    if (!value) return '-';
    return getNameCountryFromCode(value);
  },
  description: (values) => {
    const value = values[0];
    if (!value) return '-';
    return displayShortcutedArticle(value);
  },
  date: (values) => {
    const value = values[0];
    if (!value) return '-';
    return formatDateExtended(value);
  },
  fullName: (values) => {
    const [firstName, lastName] = values;
    return `${firstName || ''} ${lastName || ''}`.trim() || '-';
  },
};

export const tailwindClassForColoredButton = (color) => {
  switch (color) {
    case 'red':
    case 'purple':
    case 'gray':
    case 'green':
    return `bg-${color}-500 hover:bg-${color}-700 text-white font-bold py-2 px-4 rounded`
    default:
    return `bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`
  }
}