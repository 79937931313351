import { useState, useEffect, useMemo } from "react";
import { fetchAllFavorites, getUserById } from "../../HTTP/admin";
import { getAllPublicUsers } from "../../HTTP/user";
import { fetchCountFavorites, removeFavorite } from "../../HTTP/admin";
import { ArrowLeft } from "@mui/icons-material";
import Pagination from "../../Helper/Pagination";

export default function ArticlesFavAdmin() {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.super_admin) {
        window.location.href = '/'
    }

    const url = new URL(window.location.href)
    if (url.searchParams.get('page') === null) {
        url.searchParams.set('page', 1);
        window.history.pushState({}, '', url);
    }

    const [favs, setFavs] = useState([])
    const [nbFavs, setNbFavs] = useState(0)
    const [searchTerm, setSearchTerm] = useState(url.searchParams.get('query') === null ? '' : url.searchParams.get('query'));
    const [users, setUsers] = useState([])
    const [currentPage, setCurrentPage] = useState(parseInt(url.searchParams.get('page')));
    const [articlesPerPage] = useState(20);
    const [dataExport, setDataExport] = useState([])

    useEffect(() => {
        const funCountFavs = async () => {
            const countFavs = await fetchCountFavorites(searchTerm)
            setNbFavs(countFavs)
        }
        funCountFavs()
    }, [])

    useEffect(() => {
        const funAllFavs = async () => {
            let allFavs;
            allFavs = await fetchAllFavorites((currentPage - 1) * articlesPerPage, currentPage * articlesPerPage, searchTerm)
            setFavs(allFavs)
            const upList = await Promise.all(
                allFavs.map(async (item) => {
                    const secondResponse = await getUserById(item.user_id)
                    return {fav: item, user: secondResponse}
                })
            )
            setDataExport(upList)
        }
        funAllFavs()
    }, [])

    // Change page
    const paginate = (pageNumber) => window.location.href = "/admin/favorites?page=" + pageNumber + (url.searchParams.get("query") === null ? '' : "&query=" + url.searchParams.get("query"));

    const handleQuery = (e) => {
        e.preventDefault()
        if (url.searchParams.get('query') === null) {
            url.searchParams.set('query', searchTerm);
            window.history.pushState({}, '', url);
        }
        window.location.href = "/admin/favorites?page=1&query=" + searchTerm
    }

    const handleBackToDashboard = () => {
        window.location.href = "/admin";
      };

    const handleAddFavorite = () => {
        window.location.href = "/admin/favorites/add";
    }

    return (
        <div className="w-full px-4 sm:px-6 lg:px-8">
            <div className="flex items-center justify-between mb-6">
                <button
                onClick={handleBackToDashboard}
                className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
                >
                <ArrowLeft className="mr-2" size={20} />
                Retour
                </button>
                <h1 className="text-2xl font-bold text-center flex-grow">
                Administation des favoris
                </h1>
            </div>
            <form onSubmit={handleQuery} className="flex items-center mb-4">
                <div className="flex-grow mr-2">
                    <input
                    type="text"
                    placeholder="Rechercher par titre ou description..."
                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
                <button
                    type="submit"
                    className="bg-green-500 hover:bg-green-700 text-white font-bold mr-2 py-2 px-4 rounded-md whitespace-nowrap"
                >
                    Recherche
                </button>
                <button
                    type="button"
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-md whitespace-nowrap"
                    onClick={handleAddFavorite}
                >
                    Ajouter
                </button>
            </form>
            <div className="w-full overflow-x-auto shadow-md rounded-lg">
                <table className="w-full table-auto">
                    <thead className="bg-gray-100">
                        <tr>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Titre</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Auteur</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Lien</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        {dataExport.map((data, index) => (
                            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td className="px-4 py-4 break-words">
                                    <div className="max-w-xs lg:max-w-sm xl:max-w-md">{data.fav.title}</div>
                                </td>
                                <td className="px-4 py-4 break-words">
                                    <div className="max-w-xs lg:max-w-sm xl:max-w-md">{data.user.firstname + " " + data.user.lastname || "utilisateur inexistant"}</div>
                                </td>
                                <td className="px-4 py-4 break-words">
                                    <div className="max-w-xs lg:max-w-sm xl:max-w-md">
                                        <a href={data.fav.link} className="text-blue-600 hover:text-blue-800" target="_blank" rel="noopener noreferrer">
                                            Lien
                                        </a>
                                    </div>
                                </td>
                                <td className="px-4 py-4 break-words">
                                    <div className="max-w-xs lg:max-w-sm xl:max-w-md">{data.fav.description}</div>
                                </td>
                                <td className="px-4 py-4 whitespace-nowrap">
                                    <button 
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                        onClick={() => {
                                            if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
                                                removeFavorite(data.fav.id).then(async () => {
                                                  alert("Favori supprimée !")
                                                }).catch((err) => alert("Erreur lors de la suppression du favori !"))
                                                window.location.href = "/admin/favorites"
                                              } else {
                                                alert("Suppression annulée");
                                              }
                                        }}
                                    >
                                        Supprimer
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                itemsPerPage={articlesPerPage}
                totalItems={nbFavs}
                paginate={paginate}
                currentPage={currentPage}
            />
        </div>
    )
}