import React from 'react';
import { ArrowLeft, ArrowRight } from "@mui/icons-material";

const Pagination = ({ itemsPerPage, totalItems, paginate, currentPage }) => {
  const pageNumbers = [];
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handlePrevious = () => {
    if (currentPage > 1) {
      paginate(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      paginate(currentPage + 1);
    }
  };

  // Version mobile
  const MobilePagination = () => (
    <div className="flex items-center justify-between">
      <button
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className={`flex items-center px-4 py-2 rounded-lg ${
          currentPage === 1 
            ? 'bg-gray-100 text-gray-400' 
            : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        <ArrowLeft size={20} className="mr-1" />
        Précédent
      </button>

      <span className="text-sm text-gray-600">
        Page {currentPage} sur {totalPages}
      </span>

      <button
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className={`flex items-center px-4 py-2 rounded-lg ${
          currentPage === totalPages 
            ? 'bg-gray-100 text-gray-400' 
            : 'bg-blue-500 text-white hover:bg-blue-600'
        }`}
      >
        Suivant
        <ArrowRight size={20} className="ml-1" />
      </button>
    </div>
  );

  // Version desktop
  const DesktopPagination = () => (
    <div className="flex items-center justify-center space-x-2">
      <button
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className={`p-2 rounded-lg ${
          currentPage === 1 
            ? 'bg-gray-100 text-gray-400' 
            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
        }`}
      >
        <ArrowLeft size={20} />
      </button>

      {pageNumbers.map(number => (
        <button
          key={number}
          onClick={() => paginate(number)}
          className={`px-4 py-2 rounded-lg ${
            currentPage === number 
              ? 'bg-blue-500 text-white' 
              : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
          }`}
        >
          {number}
        </button>
      ))}

      <button
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className={`p-2 rounded-lg ${
          currentPage === totalPages 
            ? 'bg-gray-100 text-gray-400' 
            : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
        }`}
      >
        <ArrowRight size={20} />
      </button>
    </div>
  );

  return (
    <nav className="mt-4">
      <div className="block md:hidden">
        <MobilePagination />
      </div>
      <div className="hidden md:block">
        <DesktopPagination />
      </div>
    </nav>
  );
};

export default Pagination;