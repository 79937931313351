import React, {useState} from "react";
import Stack from "@mui/material/Stack";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import { Context } from "../../Helper/Context";
import etoille from "../../media/star.png"
import "./Home.css";
import "./GoogleCSE.css"
import { countries, sourceTypes, listMiddle } from "../../global/countriesAndSources"
import Translate from '../Translate/Translate';
import Map from "../Map/Map";

const user = JSON.parse(localStorage.getItem("user"))
if (sessionStorage.getItem('listmiddle') === null) {
	sessionStorage.setItem('listmiddle', "De quoi parle-t-on ?")
}

const StyledMenu = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
	))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 8,
		marginTop: theme.spacing(1),
		minWidth: 210,
		color:
			theme.palette.mode === "light"
				? "rgb(55, 65, 81)"
				: theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 25,
				color: theme.palette.text.secondary,
				// marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));

const StyledMenu1 = styled((props) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
		{...props}
	/>
	))(({ theme }) => ({
	"& .MuiPaper-root": {
		borderRadius: 8,
		marginTop: theme.spacing(1),
		minWidth: 350,
		color:
			theme.palette.mode === "light"
				? "rgb(55, 65, 81)"
				: theme.palette.grey[300],
		boxShadow:
			"rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
		"& .MuiMenu-list": {
			padding: "4px 0",
		},
		"& .MuiMenuItem-root": {
			"& .MuiSvgIcon-root": {
				fontSize: 25,
				color: theme.palette.text.secondary,
				// marginRight: theme.spacing(1.5),
			},
			"&:active": {
				backgroundColor: alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				),
			},
		},
	},
}));


export const ProfileHeader = (props) => {
	
	const vw = window.innerWidth / 100

	const [isHover, setisHover] = useState(false)

	const setSelectedCountry = props.setSelectedCountry
	const selectedCountry = props.selectedCountry
	const selectedTheme = props.selectedTheme
	const themes = props.thematics;
	const funSearchThematics = props.searchThematics;
	const funChangeDisplayMap = props.displayMap;
	const [selectedSource, setSelectedSource] = useState({name: "Toutes les sources", code_name: "all"})
	const [selectedListMiddle, setSelectedListMiddle] = useState(sessionStorage.getItem('listmiddle'))

	const [anchorSources, setAnchorSources] = useState()
	const [anchorListMiddle, setAnchorListMiddle] = useState()
	const [anchorCountries, setAnchorCountries] = useState()

	const openSources = (event) => setAnchorSources(event.currentTarget)
	const openListMiddle = (event) => setAnchorListMiddle(event.currentTarget)
	const openCountries = (event) => setAnchorCountries(event.currentTarget)
	
	const closeSources = () => setAnchorSources(null)
	const closeListMiddle = () => setAnchorListMiddle(null)
	const closeCountries = () => setAnchorCountries(null)

	const listMiddleRedirection = (item) => {
		if (item === "De quels pays parle-t-on ?") {
			sessionStorage.setItem("search_query", "")
			window.location = `/${selectedCountry.code}/the-country-speaks-of`
		}	
		else if (item === "Quels pays en parlent ?") { 
			sessionStorage.setItem("search_query", "")
			window.location = `/${selectedCountry.code}/which-countries-speak-about`
		}
		else window.location.href = `/${selectedCountry.code}/what-this-country-talk-about`
	}

	const getARandomCountry = () => {
		const country = countries[Math.floor(Math.random() * countries.length)]
		Array.from(document.getElementsByClassName('divli')).find((c) => c.innerText === country.name).scrollIntoView({ behavior: 'smooth', block: 'center' })
		setSelectedCountry(country)
	}

	return (
		<div style={{display: "flex", flexDirection: "column", flexWrap: 'wrap'}} className="mt-8">
			<div className="profile">
				<div className="icon">
					<Stack direction="row" spacing={2} id="stack_thing_in_header">
						<img 
							onMouseEnter={() => setisHover(true)} 
							onMouseLeave={() => setisHover(false)} 
							className="iconProfile" 
							src={user.profile_picture || process.env.PUBLIC_URL+"/profile_default.png" }
							alt="profile"
						 />
						
						<div className="profilename">
							<h2>
								{ `${user?.firstname} ${user?.lastname}`} 
							</h2>
							<button
								className="btnpreprofile"
								onClick={() => window.location.href = "/Subscription"}
							>
								Passer a la version Premium
							</button>
						</div>
					</Stack>
				</div>
				<div className="name"></div>

				<div className="search">
					<div className="text-left mb-1">
						{selectedTheme}
					</div>
					<div className="gcse-searchbox" />
					<div className="text-left space-x-2">
						<input type="checkbox" id="translate_query" />
						<label htmlFor="translate_query">Traduction automatique</label>
					</div>
				</div>

			</div>

			{ window.location.href[3] !== "friends-msg" && 
			<div className="droplist">
				<div className="list1">
					<Button
						style={{ backgroundColor: "#009AAD", paddingHorizontal: 120}}
						id="demo-customized-button"
						className="search_menu_selection"
						aria-controls={ Boolean(anchorSources) ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ Boolean(anchorSources) ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick = { openSources }
						endIcon={<KeyboardArrowDownIcon />}
					>

						{ selectedSource.name }

					</Button>

					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							"aria-labelledby": "demo-customized-button",
						}}
						open = { Boolean(anchorSources) }
						onClose = { closeSources }
						anchorEl={ anchorSources }
					>
					<ul className="ulflag">
						{
							sourceTypes.map((s, i) => 							
								<div className="actuins" onClick = {() => setSelectedSource(s)} key={i}> 
									<li> <img className="startArticle" src={etoille} alt="etoile" /> <a href="#">{s.name}</a> </li>
								</div>
							)
						}
					</ul>
						{/* <Divider sx={{ my: 0.5 }} /> */}
					</StyledMenu>
				</div>

				<div className="list2">
					<Button
						style={{ backgroundColor: "#009AAD", minWidth: vw * 17}}
						id="demo-customized-button"
						className="search_menu_selection"
						aria-controls={ Boolean(anchorListMiddle) ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ Boolean(anchorListMiddle) ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick = { openListMiddle }
						endIcon={<KeyboardArrowDownIcon />}
					>

						{ selectedListMiddle }

					</Button>
					<StyledMenu1
						id="demo-customized-menu"
						MenuListProps={{
							"aria-labelledby": "demo-customized-button",
						}}
						anchorEl={ anchorListMiddle }
						open={ Boolean(anchorListMiddle) }
						onClose={ closeListMiddle }
					>
						{
							listMiddle.map((item, index) => 							
								<div className="actions" onClick = {() => { setSelectedListMiddle(item); sessionStorage.setItem('listmiddle',item); closeListMiddle(); listMiddleRedirection(item) }} key={index}> 
									<span style={{ color: "black" }} > { item } </span>
								</div>
							)
						}
					</StyledMenu1>
				</div>

				<div className="list3">
					<Button
						style={{ backgroundColor: "#009AAD", paddingHorizontal: 120}}
						className="search_menu_selection_country"
						id="demo-customized-button"
						aria-controls={ Boolean(anchorCountries) ? "demo-customized-menu" : undefined}
						aria-haspopup="true"
						aria-expanded={ Boolean(anchorCountries) ? "true" : undefined}
						variant="contained"
						disableElevation
						onClick={ openCountries }
						endIcon={<KeyboardArrowDownIcon />}
					>

						{ selectedCountry.name }

					</Button>
					<StyledMenu
						id="demo-customized-menu"
						MenuListProps={{
							"aria-labelledby": "demo-customized-button",
						}}
						anchorEl={ anchorCountries }
						open={ Boolean(anchorCountries) }
						onClose={ closeCountries }
					>
						<input type="text" id="searchCountryMobile" className="m-4 w-4/5 border-2 border-cyan-600" onKeyUp={() => {
							var input, filter, div, a, i;
							input = document.getElementById("searchCountryMobile");
							filter = input.value.toUpperCase();
							div = document.getElementById("country_box_list");
							a = div.getElementsByClassName("country_selection_list");
							console.log(a.length)
					
							for (i = 0; i < a.length; i++) {
								let txtValue = a[i].textContent || a[i].innerText;
								if (txtValue.toUpperCase().indexOf(filter) > -1) {
									a[i].style.display = "";
								} else {
									a[i].style.display = "none";
								}
							}
						}}></input>
					<ul className="ulflag">
						<div id="country_box_list">
						<div className="divli" onClick={() => { funChangeDisplayMap(); closeCountries()} }> 
							<li className="country_selection_list"> 
								<div className='divstart'>  <img className="startArticle" src={etoille} alt="etoile" /> </div>   
								<a href="#">Afficher la carte</a>
							</li>
						</div>
						<div className="divli" onClick={() => { getARandomCountry(); closeCountries()} }> 
							<li className="country_selection_list"> 
								<div className='divstart'>  <img className="startArticle" src={etoille} alt="etoile" /> </div>   
								<a href="#">Aléatoire</a>
							</li>
						</div>
					{
                        countries.map((c, i) => 
                        <React.Fragment key={i}>
                            <div className="divli" onClick={() => { setSelectedCountry(c) } } key={i}> 
                                <li className="country_selection_list"> 
                                    <div className='divstart'>  <img className="startArticle" src={etoille} alt="etoile" /> </div>   
                                    <img src={process.env.PUBLIC_URL + '/flags/' + c.code + ".svg"} alt="flag" /><a href="#">{c.name}</a>
                                </li>
                            </div>
                            {
                                themes && c.code === selectedCountry.code && (
                                    themes.map((item, index) => 
                                        <div 
                                        key={index} 
                                        onClick={() => { funSearchThematics(item.theme); closeCountries()}}
                                        >
                                            <button>{item.theme}</button>
                                        </div>
                                    )
                                )
                            }
                        </React.Fragment>
                        )
                    }
					</div>
					</ul>

					{/* <Divider sx={{ my: 0.5 }} /> */}
					</StyledMenu>
				</div>
			</div>
			

		} 
	</div>

	)
}
