import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getReviewById, updateReview } from '../../HTTP/articles';

export default function EditReviewAdmin() {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.super_admin) {
        window.location.href = '/'
    }
    
  const { id } = useParams();
  const [formData, setFormData] = useState({
    id: 0,
    theme: '',
    presentation: '',
    sharefriends: false
  });

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        // Remplacez getArticleById par votre fonction réelle pour récupérer un article
        const review = await getReviewById(id);
        setFormData(review);
      } catch (error) {
        console.error("Erreur lors de la récupération de l'article:", error);
      }
    };
    if (id) {
      fetchArticle();
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const request = {
        id: formData.id,
        theme: formData.theme,
        presentation: formData.presentation,
        sharefriends: formData.sharefriends
    }
    try {
      await updateReview(request);
      alert("Revue enregistré avec succès");
      window.location.href = "/admin/reviews";
    } catch (err) {
      alert("Problème lors de l'enregistrement de l'article");
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6 text-center">Formulaire Revue</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="theme" className="block text-gray-700 text-sm font-bold mb-2">
            Thème
          </label>
          <input
            type="text"
            id="theme"
            name="theme"
            value={formData.theme}
            onChange={handleChange}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="presentation" className="block text-gray-700 text-sm font-bold mb-2">
            Présentation
          </label>
          <textarea
            id="presentation"
            name="presentation"
            value={formData.presentation}
            onChange={handleChange}
            rows="4"
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          ></textarea>
        </div>
        <div className="mb-6">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="sharefriends"
              checked={formData.sharefriends}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Autoriser les amis à publier des articles dans cette revue</span>
          </label>
        </div>
        <div className="flex items-center justify-end">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </div>
  );
}