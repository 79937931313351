import HeaderTable from "./HeaderTable";
import LineForTable from "./LineForTable";

export default function TableElements({columns, buttons, data}) {
    return (
        <div className="md:block w-full shadow-md rounded-lg">
            <div className="h-[calc(100vh-12rem)] overflow-y-auto">
                <table className="w-full table-auto">
                    <HeaderTable columns={columns} />
                    <tbody className="divide-y divide-gray-200">
                        {data.map((element, index) => (
                            <LineForTable
                                key={element.id || index}
                                index={index}
                                columns={columns}
                                element={element}
                                buttons={buttons}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}