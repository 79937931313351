import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { createArticle, getArticleById, updateArticle } from "../../HTTP/articles";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Importe le thème Snow CSS
import { encodeHTML, escapeQuotes } from "../../Helper/utils";

export default function MyArticlesEdit() {
    const [content, setContent] = useState("");

    const user = JSON.parse(localStorage.getItem("user"))
    const { id } = useParams()
    const [formData, setFormData] = useState({
        id: id,
        user_id: user.id,
        title: '',
        description: '',
        language:'',
        visible: 0
    });

    useEffect(() => {
        const funArticles = async () => {
            const resArticle = await getArticleById(id)
            setFormData(resArticle)
        }
        funArticles()
      }, [id])

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
        ...prevData,
        [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubimeChange = (content) => {
        setFormData(prevData => ({
            ...prevData,
            description: content
        }));
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await updateArticle(formData)
            alert('article mis à jour')
            window.location.href = '/myarticles'
        } catch (err) {
            alert("Problème lors de l'enregistrement de l'article");
        }
    };

    return (
        <>
        <div className="max-w-md mx-auto mt-8 p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-bold mb-6 text-center">Formulaire Article</h2>
        <form onSubmit={handleSubmit}>
            <div className="mb-4">
            <label htmlFor="title" className="block text-gray-700 text-sm font-bold mb-2">
                Titre
            </label>
            <input
                type="text"
                id="title"
                name="title"
                value={formData.title}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
            </div>
            <div className="mb-4">
            <label htmlFor="description" className="block text-gray-700 text-sm font-bold mb-2">
                Description
            </label>
            <ReactQuill
            theme="snow"
            value={formData.description}
            onChange={handleSubimeChange}
            className="notranslate"
            />
            </div>
            <div className="mb-6">
          <label className="flex items-center">
            <input
              type="checkbox"
              name="visible"
              checked={formData.visible}
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2 text-gray-700">Rendre public l'article (sera modéré par les équipes de Zemus)</span>
          </label>
        </div>
            <div className="flex items-center justify-end">
            <button
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
                Enregistrer
            </button>
            </div>
        </form>
        </div>
        </>
    );
}