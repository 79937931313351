import { useEffect, useState } from "react";
import { deleteNotification, getNotifications, updateSeenNotification } from "../../HTTP/user";
import { formatDate, formatDateExtended } from "../../Helper/utils";
import { getAdminNotifications, sendNotification, updateVisibleArticle } from "../../HTTP/admin";
import { ArrowLeft } from "@mui/icons-material";
import { getUserById } from "../../HTTP/admin";

export default function AdminNotifications() {
  const user = JSON.parse(localStorage.getItem('user'))
  if (!user.admin) {
    window.location.href = '/'
  }
    const [notifications, setNotifications] = useState([])
    const [notificationsWithUser, setNotificationsWithUser] = useState([])
    useEffect(() => {
        const funNotifications = async () => {
            const resNotifs = await getAdminNotifications()
            setNotifications(resNotifs)
        }
        funNotifications()
    }, [])

    const handleSeeNotification = async (id) => {
        const res = await updateSeenNotification(id)
        if (res) {
            alert("notification vue")
            window.location.href = "/admin/notifications"
        } else {
            alert("erreur")
        }
    }

    const handleDelete = async (id) => {
        const res = await deleteNotification(id)
        if (res) {
            alert("notification supprimée")
            window.location.href = "/admin/notifications"
        } else {
            alert("erreur")
        }
    }

    const handleViewArticle = (id) => {
      window.location.href = `https://static.zemus.info/article/?id=${id}`
    }

    const handleVisibleArticle = async (item) => {
      const res = await updateVisibleArticle(item)
      if (res) {
        alert("statut de visibilité mis à jour !")
      } else {
        alert("erreur dans la mise à jour de la visibilité de l'article!")
      }
      window.location.href = "/admin/notifications"
    }

    const handleCheckModification = async (item) => {
      window.location.href = "/admin/articles/edit/" + item.article.id + "?notification=" + item.notification.id
    }

    const handleBackToDashboard = () => {
      window.location.href = "/admin";
    };

    return (
        <>
        <div className="w-full px-4 sm:px-6 lg:px-8">
        <button
          onClick={handleBackToDashboard}
          className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
          >
          <ArrowLeft className="mr-2" size={20} />
          Retour
        </button>
      <div className="flex flex-col sm:flex-row items-center justify-normal sm:justify-between mb-6 mt-8 xl:mt-0">
        <h1 className="text-2xl font-bold text-center flex-grow">
          Notifications des modérateurs
        </h1>
      </div>

      <div className="w-full overflow-x-auto shadow-md rounded-lg">
        <table className="w-full table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Message</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
              <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {notifications.map((item, index) => (
              <tr key={item.id} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                <td className="px-4 py-4 break-words">
                  <div className={item.seen === 0 ? "font-bold " : "font-normal " + "max-w-xs lg:max-w-sm xl:max-w-md"}>{item.message}</div>
                </td>
                <td className="px-4 py-4 break-words">
                  <div className="max-w-xs lg:max-w-sm xl:max-w-md">{formatDateExtended(item.date)}</div>
                </td>
                <td className="px-4 py-4">
                <div className="flex flex-col space-y-2">
                  {
                    item.article_id && (
                    <button 
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2 w-full"
                    onClick={() => handleViewArticle(item.article_id)}
                  >
                    Voir l'article
                  </button>
                  )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
        </>
    )
}