import React, { useState } from "react";
import AuthService from "../../HTTP/auth.service";
import "./Login.css";
import bienvenue from "../../media/bienvenue.jfif";
import register from "../../media/enregis.jpeg";
import { countries } from '../../global/countriesAndSources'
import Logo from "../../media/logonobg.png";

const Login = () => {
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [RePassword, setRePassword] = useState("");
  const [auth , setauth]  = useState('login')
  /** Inscription */
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [email, setEmail] = useState("");


  const handlelogin = (e) => {
    e.preventDefault();
    if (username === "" || password === "") {
        setErrorMsg("Tous les champs sont obligatoire ! ");
        setauth('login')
        return;
    }

    AuthService.login(username, password)
    .then(() => {
        setErrorMsg("")
        const homeCountry = countries.find((co) => co.code === JSON.parse(localStorage.getItem("user")).country)
          if (homeCountry.code !== JSON.parse(sessionStorage.getItem("search_country")).code)
          {
            sessionStorage.setItem("search_country", JSON.stringify(homeCountry))
            sessionStorage.setItem("search_query", "")
          }
        setTimeout(() => window.location = "/", 600)
    })
    .catch((error) => {
        const errMessage = error?.response?.data?.message || error.message || error.toString()
        setErrorMsg(errMessage)
    })
  }

  const [error, setError] = useState({
    username: false,
    password: false,
    newpassword: false,
    Repassword: false,
  })



  const validateErr = (e) => {

    if (newpassword !== RePassword && RePassword.length > 0) {
      setErrorMsg("Les mots de passe ne sont pas identiques");
    } else {
      setErrorMsg("");
    }

    const { name, value } = e.target;

    if (name === "username") {
      if (value.trim() === "") {
        setError((prev) => ({ ...prev, username: true }));
      } else {
        setError((prev) => ({ ...prev, username: false }));
      }
    }
    if (name === "password") {
      if (value.trim() === "") {
        setError((prev) => ({ ...prev, password: true }));
      } else {
        setError((prev) => ({ ...prev, password: false }));
      }
    }
    if (name === "newpassword") {
      if (value.trim() === "") {
        console.log("err password");
        setError((prev) => ({ ...prev, newpassword: true }));
      } else {
        setError((prev) => ({ ...prev, newpassword: false }));
      }
    }
    if (name === "repassword") {
      if (value.trim() === "") {
        setError((prev) => ({ ...prev, Repassword: true }));
      } else {
        setError((prev) => ({ ...prev, Repassword: false }));
      }
    }
  }

  const handleRegistration = () => {
      if (RePassword !== newpassword){
          setErrorMsg("Les mots de passe ne sont pas identiques");
          return
      }
      AuthService.registration({
          lastname: lastname,
          firstname: firstname,
          email: email,
          country: document.querySelector('#country_select').value || "",
          password: newpassword,
      })
      .then(async (res) => {
          setSuccessMessage(res)
          //let connection = AuthService.login(email, newpassword).catch(() => setErrorMsg("une erreur inconnue est survenue, veuillez réessayer"))
          setTimeout(() => window.location = "/", 1000)
      })
      .catch((err) => setErrorMsg(err || "une erreur inconnue est survenue."))
  }


  const [islogin, setislogin] = useState(false);
  const handleSignUp = () => {
    setauth("register")
    setislogin(true)
  }

  // const handleSignIn = () => {
  //   setauth("login")
  //   setislogin(false)
  // }



  return (
    <div className="Bodylogin">
      <div className={`containerLogin right-panel-active`} id="container">
        <div className="form-container sign-up-container">
          {islogin === true ? (
            <form>
                <img
                src={Logo}
                className="logo"
                style={{ width: "220px", marginTop: "0",   maxWidth: "80%", marginBottom: "15px" }}
                alt="Logo"
              />
              { 
                errorMsg && <div className="divErrMessage"> <p style={{marginTop: 25, fontWeight: 'bold'}}>{errorMsg}</p> </div> 
              } 
              { 
                successMessage && <div className="divSuccessMessage"> <p style= {{color: "rgb(41, 175, 79)", fontSize: 19, marginTop: 25, fontWeight: 'bold'}}>{successMessage}</p> </div> 
              } 
              <h1 className="titr">Inscription</h1>
              {error.username && (
                <span className="span-danger">L'Adresse mail est requise</span>
              )}
              <input
                name="nom"
                onBlur={validateErr}
                required
                type="text"
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
                placeholder="Nom"
              />
              <input
                name="prenom"
                onBlur={validateErr}
                required
                type="text"
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
                placeholder="Prenom"
              />
              <input
                name="email"
                onBlur={validateErr}
                required
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email"
              />
              <input
                name="password"
                onBlur={validateErr}
                required
                type="password"
                value={newpassword}
                onChange={(e) => setNewpassword(e.target.value)}
                placeholder="mot de passe"
              />
              <input
                name="newpassword"
                onBlur={validateErr}
                required
                type="password"
                value={RePassword}
                onChange={(e) => setRePassword(e.target.value)}
                placeholder="confirmez le mot de passe"
              />

              <div class="input-field">
                <select id="country_select" name="country">
                  {
                    countries.map((c, i) => <option value={c.code} key={i}>{c.name}</option>)
                  }
                </select>
              </div>

  {
              <div style={{ display: "flex", width: "66%" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <input className="forgetpassipnut" style={{ width: "18px", margin: 0 }} type="checkbox"/>
                  <label style={{ marginLeft: "5px" }} className="forgetpassipnut">
                      se souvenir du mot de passe
                  </label>
                </div>
                {/*
                <div style={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  <label style={{ color: "#009AAD" }} className="forgetpassipnut"> Mot de passe oublié? </label>
                </div>
                */}
              </div>
  }
              <button type="button" style={{ background: "#009AAD", borderColor: "#009AAD" }} onClick={handleRegistration}>
                  S'inscrire
              </button>
            </form>
          ) : (
            <form>
              {errorMsg && (
                <div className="divErrMessage">
                  <p> {errorMsg}</p>
                </div>
              )}
              <h1 style={{ color: "#009AAD" }}>Connexion à Zemus</h1>
              <div className="social-container">

              </div>
              {/* <span>or use your account</span> */}
              <input
                required
                value={username || ""}
                onChange={(e) => {
                  setusername(e.target.value);
                }}
                placeholder="Adresse email"
              />
              <input
                required
                value={password || ""}
                onChange={(e) => {
                  setpassword(e.target.value);
                }}
                type="password"
                placeholder="Password"
              />
              <a href="#" onClick={handleSignUp}>
                Mot de passe oublié ?
              </a>
              <button
                style={{ background: "#009AAD", borderColor: "#009AAD" }}
                onClick={handlelogin}
              >
                Se connecter
              </button>
              <button
                style={{ marginTop: "8px", color: "black" }}
                onClick={() => { handleSignUp(); setErrorMsg("") }}
              >
                S'inscrire
              </button>
            </form>
          )}
        </div>

        <div className="overlay-container">
          <div className="overlay">
            <div
              style={ auth === "login" ? { backgroundImage: `url(${bienvenue})` } :  { backgroundImage: `url(${register})` } }
              className="overlay-panel overlay-left"
            >
              {/* <img className="zlogo" src={zlogo} /> */}
              {/* <img className="zlogo" src={Ligne} /> */}
              {/* <div className="bienvenudiv">
          <p>C’est un plaisir de vous revoir</p>
<h1>BIENVENUE</h1>

          </div> */}
            </div>

            {/* <div className="overlay-panel overlay-right">
            <img className="zlogo" src={zlogo} />
 
          <div className="bienvenudiv">
          <p>Enregistrement de l’utilisateur</p>
<h1>BIENVENUE</h1>

          </div>
              
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
