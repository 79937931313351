import React from 'react';
import * as helpers from "../../Helper/utils"
import "./ReviewBlocks.css"
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import PeopleIcon from '@mui/icons-material/People';
import { width } from '@amcharts/amcharts4/.internal/core/utils/Utils';
const ReviewBlocks = ({ reviews, onDeleteReview, edition }) => {
  const user = JSON.parse(localStorage.getItem('user'))
  // Fonction pour déterminer les classes CSS en fonction du nombre de reviews
  const getBlockSizeClass = (index, total) => {
    if (total % 4 === 0 || (total % 4 > 0 && index < total - 1)) {
      // Si le nombre total de reviews est divisible par 4 ou pour tous sauf le dernier bloc dans le cas d'un nombre impair de reviews
      return 'w-1/2 h-1/2';
    } else if (total % 2 === 0) {
      // Si le nombre total de reviews est pair mais pas divisible par 4
      return 'w-1/2 h-full';
    } else {
      // Pour le dernier bloc d'un nombre impair de reviews
      return 'w-full h-full';
    }
  };
  if (!Array.isArray(reviews)) {
    console.error('reviews doit être un tableau', reviews);
    return null; // Ou retourner un fallback UI
  }

  
  return (
    <div className="reviews-container flex flex-wrap m-2">
      {reviews.map((review, reviewIndex) => (
        <div key={reviewIndex} className="review w-full lg:w-1/2 xl:w-1/3">
          <div className="review-block bg-white rounded-lg shadow m-2">
            <a href={`https://static.zemus.info/?id=${review.id}`}  className="review-header p-4 block">
                <strong className="block text-uppercase">{review.theme}</strong>
            {
              review.sharefriends ? <img className="w-6 absolute top-1 right-1" src={process.env.PUBLIC_URL + "/unlock.svg"}></img> : <img className="flex absolute top-1 right-1 w-6" src={process.env.PUBLIC_URL + "/lock.svg"}></img>
            }
                <p  className="block">{review.presentation}</p>
            </a>
            <div className="review-inner p-4">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                {review.articles && review.articles.map((article, articleIndex, { length }) => {
                    let colSpan = "col-span-1";
                    let heightSpan = "w-full h-24 object-cover rounded";
                    if (length === 3 && articleIndex === 0) { // Premier article prend deux colonnes s'il y a trois articles
                      colSpan = "md:col-span-2";
                      heightSpan = "w-full h-24 object-cover rounded"
                    } else if (length === 4) { // Chaque article prend exactement un quart de l'espace
                      colSpan = "md:col-span-1";
                      heightSpan = "w-full h-24 object-cover rounded"
                    }

                    return (
                      <div key={articleIndex} className={`${colSpan}`}>
                        <div className="article-content bg-white rounded-lg shadow relative">
                          <a href={ helpers.htmlDecode(article.link)} target="blank">
                            <img src={ helpers.htmlDecode(article.image || process.env.PUBLIC_URL + "/news_icon2.jpg") /**  IMAGE  */} alt={article.title} className={`${heightSpan}`} /> 
                            <div className="article-title p-2">
                              <h3 className="py-1">{article.title} </h3>
                              <span className="article-origin block"><strong>{article.country}</strong> | {helpers.getDomainName(article.link)} </span>
                            </div>
                          </a>
                        </div>
                      </div>
                    );
                  })}
              </div> 
              <div className="review-footer mt-3 flex">
              <a href={`https://static.zemus.info/?id=${review.id}`}  className="mr-2 mb-2 bg-yellow-300 hover:bg-yellow-100 text-black font-bold py-2 px-4 rounded-full inline-flex items-center space-x-2">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path></svg> 
              </a>
              {
                review.sharefriends === 1 && user.id !== review.user_id && <a href={`/reviews/${review.id}/addfavourites`} title="Ajouter un article" className="mr-2 mb-2 bg-blue-700 hover:bg-blue-400 text-black font-bold py-2 px-4 rounded-full inline-flex items-center space-x-2">
                <ArrowOutwardIcon />
              </a>
              }
              
              {edition && edition === "true" && (
                  <>
                    <a href={`/editreviews/${review.id}`} className="bg-gray-500 hover:bg-gray-700 text-white py-2 px-4 rounded-full flex items-center space-x-2 inline-block mr-2 mb-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                        <path fillRule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10a2 2 0 002 2h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clipRule="evenodd" />
                      </svg>
                    </a>

                    <a href="#"
                      onClick={() => onDeleteReview(review.id)}
                      className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded-full inline-flex items-center inline-block mb-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                    </a>
                  </>
                )} 
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
     
  );
};

export default ReviewBlocks;
