import { useEffect, useState, useMemo } from "react";
import { fetchQueryReviews, fetchReviewCount, fetchReviews, getNumberOfQueryReview, getUserById } from "../../HTTP/admin";
import { deleteReview } from "../../HTTP/articles";
import { getAllPublicUsers } from "../../HTTP/user";
import { ArrowLeft } from "@mui/icons-material";
import TableElements from "./TableElements";
import SearchComponent from "./SearchComponent";
import Pagination from "../../Helper/Pagination";

export default function ReviewAdmin() {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.super_admin) {
        window.location.href = '/'
    }

    const url = new URL(window.location.href)
    if (url.searchParams.get('page') === null) {
        url.searchParams.set('page', 1);
        window.history.pushState({}, '', url);
    }

    const [reviews, setReviews] = useState({count:0,rows:[]})
    const [searchDone, setSearchDone] = useState(false)
    const [searchTerm, setSearchTerm] = useState(url.searchParams.get('query') === null ? '' : url.searchParams.get('query'));
    const [currentPage, setCurrentPage] = useState(parseInt(url.searchParams.get('page')));
    const [usersPerPage] = useState(10);


    useEffect(() => {
      const funReviews = async () => {
          const getReviews = await fetchReviews((currentPage - 1) * 10, currentPage * 10, searchTerm)
          setReviews(getReviews)
          setSearchDone(true)
      }
      funReviews()
    },[])
    
    const paginate = (pageNumber) => {
      window.location.href = "/admin/reviews?page=" + pageNumber + (url.searchParams.get("query") === null ? '' : "&query=" + url.searchParams.get("query"))
  }

  const handleQuery = (e) => {
    e.preventDefault()
    if (url.searchParams.get('query') === null) {
        url.searchParams.set('query', searchTerm);
        window.history.pushState({}, '', url);
    }
    window.location.href = "/admin/reviews?page=1&query=" + searchTerm
}

    const handleEditReview = (id) => {
      window.location.href = "/admin/reviews/edit/" + id
  }

  const handleArticlesInReview = (id) => {
    window.location.href = "/admin/reviews/articles/" + id
}
    const handleAddReview = () => {
      window.location.href = "/admin/reviews/add"
    }

    const handleBackToDashboard = () => {
      window.location.href = "/admin";
    };

    const handleDeleteReview = (id) => {
      if (window.confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
      deleteReview(id).then(async () => {
        alert("Revue supprimée !")
      }).catch((err) => alert("Erreur lors de la suppression de la revue !"))
      window.location.href = "/admin/reviews"
      } else {
        alert("Suppression annulée");
      }
    }

    return (
        <>
        <div className="w-full px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between mb-6">
        <button
          onClick={handleBackToDashboard}
          className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <ArrowLeft className="mr-2" size={20} />
          Retour
        </button>
        <h1 className="text-2xl font-bold text-center flex-grow">
          Modération des revues de presse
        </h1>
      </div>
      <SearchComponent
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleQuery={handleQuery}
        otherButton={{title:'Ajouter', handle:handleAddReview}}
      />
    <TableElements
      columns={[
        {props:'theme', display:"Thème"},
        {props:'presentation', display:"Présentation"},
        {props:['user.firstname', 'user.lastname'], display:"Auteur"},
        {props: '', display:"Actions"}
      ]}
      data={reviews.rows}
      buttons={[{display:'Voir les articles',action:handleArticlesInReview, color:'green', props:'id'}, 
        {display:'Éditer',action:handleEditReview, color:'blue', props:'id'}, 
        {display:'Supprimer',action:handleDeleteReview, color:'red', props:'id'}]}
      />
    <Pagination
                itemsPerPage={usersPerPage}
                totalItems={reviews.count}
                paginate={paginate}
                currentPage={currentPage}
            />
  </div>
        </>
    )
}