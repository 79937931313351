import React, { useState, useEffect } from "react"
import "./Admin.css"
import { getAdminCountNotifications } from "../../HTTP/admin"

export default function Admin() {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (!localUser.admin) {
        window.location.href = '/'
    }

    const [countNotifications, setCountNotifications] = useState(0)

    useEffect(() => {
        const funGetCountAdminNotifications = async () => {
            const result = await getAdminCountNotifications()
            setCountNotifications(result)
        }
        funGetCountAdminNotifications()
    }, [])

    const handleUserAdmin = () => {
        window.location.href = "/admin/users"
    }

    const handleReviewAdmin = () => {
        window.location.href = "/admin/reviews"
    }

    const handleFavoritesAdmin = () => {
        window.location.href = "/admin/favorites"
    }
    const handleArticlesAdmin = () => {
        window.location.href = "/admin/articles"
    }
    const handleNotificationsAdmin = () => {
        window.location.href = "/admin/notifications"
    }

    return (
        <>
        <h1 className="text-center">
            Admin
        </h1>
        <div className="flex flex-col items-center mt-4 w-full lg:justify-around lg:flex-row">
    {localUser.super_admin === 1 && <button
        className="w-1/2 lg:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-2"
        type="button"
        onClick={handleUserAdmin}
    >
        Utilisateurs
    </button>}
    <button
        className="w-1/2 lg:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-2"
        type="button"
        onClick={handleArticlesAdmin}
    >
        Articles
    </button>
    <button
        className="w-1/2 lg:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mb-2"
        type="button"
        onClick={handleNotificationsAdmin}
    >
        Notifications {countNotifications > 0 ? "("+countNotifications+")" : ""}
    </button>
</div>
        </>
    )
}